import { Suspense, useEffect } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { FullLoader } from 'ui'
import { AppRoutes } from 'pages/routes'
import { Modals } from 'features/modal/modals'
import Toasts from 'features/toasts/toasts'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import { useLocation } from 'react-router-dom'
import WelcomePage from 'pages/welcome/page'
import Broadcast from '../broadcast/broadcast'
import { useWindowActions } from '../../models/window'
import {useDispatch, useSelector} from "react-redux";
import {userSelector} from "../../models/user/selectors";
import {setIsChief} from "../../models/user";

import 'react-loading-skeleton/dist/skeleton.css'

const App = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const telegramData = window.Telegram.WebApp
    const { closeWindow } = useWindowActions()
    const user = useSelector(userSelector)

    useEffect(() => {
        window.Telegram.WebApp.ready()
        window.Telegram.WebApp.expand()
    }, [])

    useEffect(() => {
        window.Telegram.WebApp.ready()

        if (location.pathname !== '/') {
            window.Telegram.WebApp.BackButton.show()
        } else {
            window.Telegram.WebApp.BackButton.hide()
        }

        window.Telegram.WebApp.BackButton.onClick(() => {
            window.history.back()
            closeWindow()
        })
    }, [location.pathname])

    useEffect(() => {
        const updateAppHeight = () => {
            document.documentElement.style.setProperty(
                '--app-height',
                `${document.documentElement.clientHeight}px`
            )
        }

        updateAppHeight()
        window.addEventListener('resize', updateAppHeight)

        return () => {
            window.removeEventListener('resize', updateAppHeight)
        }
    }, [])

    useEffect(() => {
        if (user && (user.telegram_id === '671984006' || user.telegram_id === '249752697' || user.telegram_id === '258505831')) {
        // if (user && user.telegram_id === '671984006') {
            dispatch(setIsChief())
        }
    }, [user])

    const render = () => {
        if (telegramData.initData) {
            return (
                <TonConnectUIProvider manifestUrl="https://wr-egg.com/tonconnect-manifest.json">
                    <SkeletonTheme
                        baseColor="rgba(0, 0, 0, .2)"
                        highlightColor="rgba(0, 0, 0, .2)"
                        inline
                    >
                        <Suspense fallback={<FullLoader />}>
                            <HelmetProvider>
                                <Helmet>
                                    <title>Touch the EGGS!</title>
                                </Helmet>
                                <AppRoutes />
                                <Modals />
                                <Toasts />
                                <Broadcast />
                            </HelmetProvider>
                        </Suspense>
                    </SkeletonTheme>
                </TonConnectUIProvider>
            )
        }

        return <WelcomePage />
    }

    return render()
}

export default App
