import { useState, useEffect } from "react";
import cn from 'classnames'

import c from "./assets/sass/slider.module.scss";

export const Slider = ({ onClose }) => {
    const [slide, setSlide] = useState(1);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const buttonHandler = () => {
        if (slide < 4) {
            setSlide((prev) => (prev < 4 ? prev + 1 : 4));
        } else {
            onClose()
        }
    };

    return (
        <div
            className={c.component}
            style={{ "--width": `${width}px`, "--translateX": `-${(slide - 1) * width}px` }}
        >
            <div className={c.row}>
                <div className={c.slide}>
                    <div className={c.text}>
                        <span className={c.title}>Welcome on board mate!</span>
                        <span className={c.description}>
                            EGGS it's a meme cryptocurrency. Collect tokens for fun and claim your profit
                        </span>
                    </div>
                </div>
                <div className={c.slide}>
                    <div className={c.text}>
                        <span className={c.title}>Invite Friends and Get Eggs!</span>
                        <span className={c.description}>
                            Receive 1 egg for every box your friend opens
                        </span>
                    </div>
                </div>
                <div className={c.slide}>
                    <div className={c.text}>
                        <span className={c.title}>Open Other Players Profiles</span>
                        <span className={c.description}>
                            Find boxes, open them, and get Eggs!
                        </span>
                    </div>
                </div>
                <div className={c.slide}>
                    <div className={c.text}>
                        <span className={c.title}>Complete Tasks  and Earn Eggs!</span>
                    </div>
                </div>
            </div>
            <div className={c.bottom}>
                <ul className={c.pads}>
                    <li className={cn(c.pad, {[c.active]: slide === 1})}/>
                    <li className={cn(c.pad, {[c.active]: slide === 2})}/>
                    <li className={cn(c.pad, {[c.active]: slide === 3})}/>
                    <li className={cn(c.pad, {[c.active]: slide === 4})}/>
                </ul>
                <button className={c.button} onClick={buttonHandler}>
                    {slide < 4 ? 'Next' : 'GO!'}
                </button>
            </div>
        </div>
    );
};
